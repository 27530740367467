import React from 'react';

const RepairPageA001 = () => {
    return (
        <div className="flex flex-col h-screen w-full bg-black justify-center items-center p-4 text-center text-white">
            <h1 className="text-3xl font-bold mb-6">Kibu Aux Link Fix</h1>
            <p className="mb-10 w-[60%]">Some customers have experienced connection issues with their headphones due to the Kibu aux connectors not extending far enough outward, preventing cables from fully connecting on both sides. To address this, we’ve introduced small spacers that resolve the problem effectively.</p>
            <a
                href="https://youtu.be/GTqE4BTCyyo"
                className="hover:bg-white bg-orange hover:text-orange text-white font-semibold py-4 px-8 rounded-md transition-colors duration-200"
            >
                Go to tutorial
            </a>
        </div>
    );
}

export default RepairPageA001; 