import React from 'react';

const RepairPage = () => {
    return (
        <div className="flex flex-col h-screen w-full bg-green justify-center items-center p-4 text-center text-white">
            <h1 className="text-3xl font-bold mb-6">Repair Site Coming Soon</h1>
            <p className="mb-5">For repair inquiries, please contact us at:</p>
            <a
                href="mailto:repair@kibu.family"
                className="bg-white hover:bg-green-dark text-green hover:text-white font-semibold py-4 px-8 rounded-md transition-colors duration-200"
            >
                repair@kibu.family
            </a>
        </div>
    );
}

export default RepairPage; 